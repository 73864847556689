import React from 'react';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link'

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'© '}
      {/* <Link color='inherit' href=''> */}
      Golden Tech Development
      {/* </Link>{' '} */} {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
