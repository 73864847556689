import React from 'react';
import TextField from '@mui/material/TextField';
import css from './Input.module.css';

const Input = ({ handleError, outerCss, required = true, ...props }) => {
  return (
    <TextField
      size="small"
      variant="outlined"
      required={required}
      color="primary"
      style={outerCss ?? outerCss}
      className={!outerCss ? css.input : undefined}
      inputProps={{ style: { fontSize: 20 } }}
      error={handleError ? true : false}
      helperText={handleError ?? handleError}
      {...props}
    />
  );
};

export default Input;
