import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Grid, IconButton, Typography } from '@mui/material';

import { UserContext } from '../context/user.js';

const Item = ({
  assessment,
  current,
  target,
  title,
  valueLatestAt,
  handleAddClick,
  handleEditClick,
  handleInfoClick,
  handleVisibleClick,
}) => {
  const theme = useTheme();
  const { date } = React.useContext(UserContext);
  const diff = valueLatestAt && date - new Date(valueLatestAt);
  const divider = target && <span> / </span>;
  const [days] = useState(diff && Math.floor(diff / (1000 * 60 * 60 * 24)));
  const [red] = useState(theme.palette.red);
  const [value] = useState(current !== -1 && (current || 0));
  const valueStyle =
    value === 0 && assessment
      ? { fontWeight: 'bold', color: red }
      : { fontWeight: 'bold' };
  const reminder = assessment && days > 2;
  const color =
    current > target ? 'over' : current !== target ? 'undone' : undefined;

  return (
    <Grid container>
      <Grid item xs={1} />
      <Grid
        item
        xs={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container direction="column">
          <Typography
            variant="h4"
            component="h1"
            sx={{
              marginTop: '10px',
              fontSize: '15px',
              fontWeight: 'bold',
              textAlign: 'left',
              paddingRight: '20px',
            }}
            color={target && color}
          >
            {title}
          </Typography>
          {reminder ? (
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontSize: '8px',
                fontWeight: 'bold',
                textAlign: 'left',
                paddingRight: '20px',
                color: red,
              }}
            >
              {<FormattedMessage id="More" />} {days}{' '}
              {<FormattedMessage id="MoreDaysAgo" />}
            </Typography>
          ) : null}
        </Grid>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            marginTop: '10px',
            fontSize: '13px',
            fontWeight: 'normal',
            textAlign: 'right',
            paddingRight: '20px',
            whiteSpace: 'nowrap',
          }}
        >
          <span style={valueStyle}>{current !== -1 && (current || 0)}</span>
          {current !== -1 && target && divider}
          <span style={{ fontSize: '11px' }}>{target}</span>
        </Typography>
      </Grid>

      <Grid item xs={6} textAlign={'left'} sx={{ paddingLeft: '20px' }}>
        <IconButton color="primary" onClick={handleInfoClick}>
          <InfoIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleVisibleClick}>
          <VisibilityOffIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton color="primary" onClick={handleAddClick}>
          <AddIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            borderTop: '1px solid gray',
            marginTop: '10px',
            width: '100%',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Item;
