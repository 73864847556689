/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Copyright from '../components/Copyright.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons.jsx';

import { AppContext } from '../context/app.js';

import css from '../themes/styles.js';

import YouTube from 'react-youtube';

const Home = () => {
  const { themeTitle, toggleTheme, locale, toggleLocale } =
    useContext(AppContext);
  const navigate = useNavigate();

  const videoIds = {
    en: 'FcN-jWjSdZ0',
    ru: '6386ok2-1kU',
    uk: 'SFlvRKsJ75Y',
  };

  const [loading, setLoading] = useState(true);
  const [videoId, setVideoId] = useState();

  const isBrowser = typeof window !== 'undefined';
  const isMobile = isBrowser && window.innerWidth <= 768;

  const handleToggleChange = (locale) => {
    toggleLocale(locale);
    setVideoId(videoIds[locale]);
  };

  const opts = {
    height: isMobile ? '240' : '360',
    width: isMobile ? '320' : '640',
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const locale = localStorage.getItem('selectedLocale');
        const videoId = videoIds[locale];
        setVideoId(videoId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="AppTitle" />} />

      <Grid item xs={3} />
      <Grid item xs={6}>
        <TwoButtons
          leftTitle={<FormattedMessage id="GetStarted" />}
          handleLeftClick={() => {
            navigate('/register');
          }}
          rightTitle={<FormattedMessage id="SignIn" />}
          handleRightClick={() => {
            navigate('/login');
          }}
        />
      </Grid>
      <Grid item xs={3} sx={{ height: '10vh' }} />

      <Grid item xs={12} textAlign={'center'}>
        <YouTube videoId={videoId} opts={opts} />
      </Grid>

      <Grid item xs={3} />
      <Grid item xs={3} sx={{ ...css.gridRight }}>
        <FormControl>
          <FormLabel id="theme-radio-buttons">
            <FormattedMessage id="Theme" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={themeTitle}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="darkTheme"
              control={<Radio onChange={() => toggleTheme('darkTheme')} />}
              label={<FormattedMessage id="DarkTheme" />}
            />
            <FormControlLabel
              value="lightTheme"
              control={<Radio onChange={() => toggleTheme('lightTheme')} />}
              label={<FormattedMessage id="LightTheme" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <FormLabel id="language-radio-buttons">
            <FormattedMessage id="Language" />
          </FormLabel>
          <RadioGroup
            aria-labelledby="language-radio-buttons"
            value={locale}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="en"
              control={<Radio onChange={() => handleToggleChange('en')} />}
              label={<FormattedMessage id="EN" />}
            />
            <FormControlLabel
              value="ru"
              control={<Radio onChange={() => handleToggleChange('ru')} />}
              label={<FormattedMessage id="RU" />}
            />
            <FormControlLabel
              value="uk"
              control={<Radio onChange={() => handleToggleChange('uk')} />}
              label={<FormattedMessage id="UA" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12} sx={{ height: '3vh' }} />
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Home;
