import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import Input from './Input/Input.jsx';
import DeleteButton from './DeleteButton.jsx';

const InputAndDelete = ({
  inputIndex,
  inputValue,
  onBlur,
  onChange,
  onClick,
  onDelete,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState('');

  const handleInputChange = (event) => {
    setValue(event.target.value);
    onChange(event, inputIndex);
  };

  const handleButtonDeleteClick = () => onDelete(inputIndex);

  const handleInputOnClick = (event) => onClick(event, inputIndex);

  const handleInputOnBlur = () => onBlur(inputIndex);

  return (
    <Grid item xs={12} textAlign="center">
      <Input
        outerCss={{ maxWidth: '245px' }}
        key={inputIndex}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleInputOnClick}
        onBlur={handleInputOnBlur}
      />
      <DeleteButton handleRemoveClick={handleButtonDeleteClick} />
    </Grid>
  );
};

export default InputAndDelete;
