import React from 'react';
import TwoButtons from '../components/TwoButtons';
import { Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

const SyncProcess = ({ handleButtonClick, handleRefreshClick }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: 'primary',
        color: 'over',
      }}
    >
      <Typography
        color="red"
        component="h1"
        gutterBottom
        paddingBottom="10"
        variant="h4"
      >
        <FormattedMessage id="PleaseWait" />
      </Typography>
      <CircularProgress />
      <Typography sx={{ fontSize: '18px', paddingTop: 2, paddingBottom: 2 }}>
        <FormattedMessage id="SyncProcess" />
      </Typography>
      <TwoButtons
        leftTitle={<FormattedMessage id="Refresh" />}
        handleLeftClick={handleRefreshClick}
        rightTitle={<FormattedMessage id="TurnOffSync" />}
        handleRightClick={handleButtonClick}
      />
    </Container>
  );
};

export default SyncProcess;
