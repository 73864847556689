import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';

import Button from '../components/ButtonS.jsx';
import Error from '../components/Error.jsx';
import Loader from '../components/Loader.jsx';
import Success from '../components/Success.jsx';
import Title from '../components/Title.jsx';
import ValueItem from '../components/ValueItem.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods';

import { UserContext } from '../context/user.js';

const Item = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const itemRef = useRef();

  const [api, setApi] = useState({});
  const { date } = useContext(UserContext);
  const [error, setError] = useState('');
  const [editButton, setEditButton] = useState(true);
  const [deletedMsg, setDeletedMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [disabledAddButton, setDisabledAddButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [title, setTitle] = useState('');
  const [values, setValues] = useState([]);

  const now = new Date().toISOString().split('T')[0];
  const disabled = date.toISOString().split('T')[0] !== now;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        setApi(api);
        const { count } = await api.item.getValuesCount({ id });
        const { item } = await api.item.get({ id });

        if (!item) {
          navigate('/today');
          return;
        }

        const { values } = await api.value.getByDate({ id, date });
        if (count === 0) setShowDeleteButton(true);
        setTitle(item.title);
        setValues(values);
        setLoading(false);
        itemRef.current = item;
      } catch (error) {
        setLoading(false);
        setError(errorMessageHandler(error));
      }
    }
    fetchData();
  }, []);

  const handleDeleteClick = async () => {
    try {
      setLoading(true);
      if (!deletedMsg) {
        await api.item.delete({ id });
        setDeletedMsg(<FormattedMessage id="RemovedItem" />);
        setShowDeleteButton(false);
        setDisabledAddButton(true);
        setEditButton(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title
        text={title ?? 'Unknown'}
        editButton={editButton}
        handleEditClick={() => navigate(`/edit-item/${id}`)}
      />
      <Grid item xs={12} textAlign={'center'}>
        <Typography>{itemRef.current.description}</Typography>
      </Grid>
      {values.length ? (
        <Grid item xs={12} textAlign="center">
          <div
            style={{
              // height: '70vh',
              overflow: 'auto',
              margin: '10px 50px 0 50px',
            }}
          >
            {values.map((valueList, index) => (
              <ValueItem
                key={index}
                value={valueList.value}
                handleEditClick={() =>
                  navigate(
                    `/values/value/${id}/${title}/${itemRef.current.valueType}/${valueList.id}`,
                  )
                }
              />
            ))}
          </div>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Title color="gray" text={<FormattedMessage id="NoItems" />} />
        </Grid>
      )}
      <Grid item xs={12} textAlign={'center'}>
        <Box display={'flex'} justifyContent="center" gap={2}>
          <Button onClick={() => navigate('/today')}>
            {<FormattedMessage id="Back" />}
          </Button>
          {!disabled ? (
            <Button
              disabled={disabledAddButton}
              onClick={() =>
                navigate(
                  `/values/${itemRef.current.id}/${itemRef.current.title}/${itemRef.current.valueType}/item`,
                )
              }
            >
              {<FormattedMessage id="Add" />}
            </Button>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        {showDeleteButton ? (
          <Button onClick={handleDeleteClick}>
            {<FormattedMessage id="Delete" />}
          </Button>
        ) : null}
      </Grid>
      {error && <Error text={error} />}
      {deletedMsg.length !== 0 && <Success text={deletedMsg} />}
    </Grid>
  );
};

export default Item;
