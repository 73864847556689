import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography } from '@mui/material';

import Button from '../components/ButtonS.jsx';
import Title from '../components/Title.jsx';

import { FaYoutube, FaTelegram } from 'react-icons/fa';

const About = () => {
  const navigate = useNavigate();
  const color = 'primary';

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="About" />} />

      <Grid item xs={12} textAlign={'center'}>
        <Typography variant="h3" component="h1" color={color} fontSize={'14px'}>
          {<FormattedMessage id="AboutAnyQuestionsTitle" />}
        </Typography>
        <Typography
          variant="h3"
          component="h1"
          color={color}
          fontSize={'16px'}
          sx={{ fontWeight: 'bold' }}
        >
          goldentechdevelopment@gmail.com
        </Typography>
        <br />
        <Typography
          variant="h3"
          component="h1"
          color={color}
          fontSize={'14px'}
          style={{ maxWidth: '500px', margin: '0 auto' }}
        >
          {<FormattedMessage id="AboutAnyQuestionsDescription" />}
        </Typography>
      </Grid>

      <Grid item xs={12} textAlign={'center'}>
        <a
          href="https://www.youtube.com/@GoldenTechDevelopment"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube
            size={32}
            style={{ marginRight: '20px', color: '#FF0000' }}
          />
        </a>
        <a
          href="https://t.me/golden_tech_dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram
            size={32}
            style={{ marginRight: '20x', color: '#229ED9' }}
          />
        </a>
      </Grid>

      <Grid item xs={12} textAlign={'center'}>
        <Button onClick={() => navigate('/menu')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>

      <Grid item xs={12} textAlign={'center'}>
        <p>version: 2.1.4</p>
      </Grid>
    </Grid>
  );
};

export default About;
