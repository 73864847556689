import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';

import getDaysByDates from '../utils/getDaysByDates';
import getDaysAgoTitle from '../utils/getDaysAgoTitle';

const SubItem = ({ details }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const latestDate = new Date(details.latestAt);
  const days = getDaysByDates(latestDate, new Date());

  console.log(days);

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Typography align="right" fontWeight="bold">
          {details.title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/detail/${details.itemId}/${details.title}`)}
        >
          <InfoIcon color="primary" />
        </IconButton>
      </Grid>
      <Grid item xs={5}>
        {days > 0 && (
          <Typography align="left">
            {getDaysAgoTitle(days, intl)}, {latestDate.toLocaleDateString()}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SubItem;
