import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Error from '../components/Error.jsx';
import HiddenItem from '../components/HiddenItem.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods';

const HiddenItems = () => {
  const navigate = useNavigate();

  const [api, setApi] = useState({});
  const [error, setError] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchItems() {
    try {
      const api = await fetchApiMethods();
      setApi(api);
      const { items } = await api.item.findByUser({ visible: false });
      setLoading(false);
      return items;
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
      return [];
    }
  }

  useEffect(() => {
    async function fetchData() {
      const itemList = await fetchItems();
      setItems(itemList);
    }
    fetchData();
  }, []);

  const handleVisibleClick = async (item) => {
    try {
      setLoading(true);
      const updatedItems = items.filter((i) => i.id !== item.id);
      setItems(updatedItems);
      await api.item.update({
        id: item.id,
        target: item.target,
        visible: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
      return [];
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="HiddenItems" />} />
      {items.length ? (
        <Grid item xs={12} textAlign="center">
          <div style={{ height: '75vh', overflow: 'auto' }}>
            {items.map((item) => (
              <HiddenItem
                key={item.id}
                title={item.title}
                handleVisibleClick={() => handleVisibleClick(item)}
              />
            ))}
          </div>
        </Grid>
      ) : (
        <Title color="gray" text={<FormattedMessage id="NoItems" />} />
      )}
      <Grid item xs={12} textAlign="center">
        <Button onClick={() => navigate('/today')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>
      {error && <Error text={error} />}
    </Grid>
  );
};

export default HiddenItems;
