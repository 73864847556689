import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';

const ItemStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  gap: 1,
  // marginBottom: '5px',
  // marginTop: '10px',
};
const fontSize = '16px';

const Total = ({ total }) => {
  if (total === 0) return <></>;

  return (
    <Grid item xs={12} sx={ItemStyle}>
      <Typography color="details" sx={{ fontSize }}>
        {<FormattedMessage id="Total" />}
      </Typography>
      <Typography color="details" sx={{ fontSize, fontWeight: 'bold' }}>
        {total}
      </Typography>
    </Grid>
  );
};

export default Total;
