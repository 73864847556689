import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DateDigitDetail from './DateDigitDetail.jsx';

const DetailsMinMaxLatestLine = ({
  title,
  min,
  dateMin,
  colorMin,
  max,
  dateMax,
  colorMax,
  latest,
  dateLatest,
}) => {
  const intl = useIntl();
  const typeLatest = intl.formatMessage({ id: 'Latest' }).toLocaleLowerCase();

  if (dateMax === dateMin) min = null;
  if (dateMin === dateLatest) min = null;
  if (dateMax === dateLatest) max = null;

  if (!max && !min && !latest) return <></>;

  return (
    <Grid container sx={{ marginBottom: '5px' }}>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex ' }}
      >
        <Typography
          color="details"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex ' }}
      >
        {!!min && (
          <DateDigitDetail
            color={colorMin}
            type={<FormattedMessage id="minimum" />}
            date={dateMin}
            value={min}
          />
        )}
        {!!max && (
          <DateDigitDetail
            color={colorMax}
            type={<FormattedMessage id="maximum" />}
            date={dateMax}
            value={max}
          />
        )}
        {!!latest && (
          <DateDigitDetail
            color="details"
            type={typeLatest}
            date={dateLatest}
            value={latest}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DetailsMinMaxLatestLine;
