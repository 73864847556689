import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

const DateDigitDetail = ({ type, date, value, color }) => {
  return (
    <div style={{ paddingLeft: '20px' }}>
      <Grid container direction="column" alignItems="center">
        <Typography color={color} sx={{ fontSize: '12px' }}>
          {date}
        </Typography>
        <Typography color={color} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
          {value}
        </Typography>
        <Typography color={color} sx={{ fontSize: '12px' }}>
          {type}
        </Typography>
      </Grid>
    </div>
  );
};

export default DateDigitDetail;
