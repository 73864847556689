const getLocalTimeZone = (date) => {
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  const timezoneOffsetHours = Math.floor(
    Math.abs(timezoneOffsetInMinutes) / 60,
  );
  const timezoneOffsetSign = timezoneOffsetInMinutes < 0 ? '+' : '-';
  return parseInt(`${timezoneOffsetSign}${timezoneOffsetHours}`);
};

export default getLocalTimeZone;
