import React from 'react';

import { Button, Typography } from '@mui/material';

const ValueItem = ({ value, handleEditClick }) => {
  return (
    <Button onClick={handleEditClick} style={{ cursor: 'pointer' }}>
      <Typography sx={{ fontSize: '25px' }} style={{ padding: '20px' }}>
        {value}
      </Typography>
    </Button>
  );
};

export default ValueItem;
