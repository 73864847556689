import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ItemStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
  marginTop: '20px',
};

const TypeNumberDetails = ({
  min,
  dateMin,
  max,
  dateMax,
  minTitle,
  maxTitle,
}) => {
  const fontSize = '16px';

  return (
    <Grid container textAlign={'center'}>
      <Grid item xs={12} sx={ItemStyle}>
        {!!min && (
          <div style={{ paddingRight: '20px' }}>
            <Typography color="details" sx={{ fontSize }}>
              {<FormattedMessage id={`${minTitle}`} />}
            </Typography>
            <Typography color="min" sx={{ fontSize, fontWeight: 'bold' }}>
              {min}
            </Typography>
            <Typography color="min" sx={{ fontSize: '12px' }}>
              {dateMin}
            </Typography>
          </div>
        )}
        {!!max && (
          <div>
            <Typography color="details" sx={{ fontSize }}>
              {<FormattedMessage id={`${maxTitle}`} />}
            </Typography>
            <Typography color="max" sx={{ fontSize, fontWeight: 'bold' }}>
              {max}
            </Typography>
            <Typography color="max" sx={{ fontSize: '12px' }}>
              {dateMax}
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default TypeNumberDetails;
