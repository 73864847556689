import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const DetailsLine = ({ title, daysTitle, date }) => {
  const fontSize = '14px';
  if (!daysTitle) return <></>;

  return (
    <Grid container sx={{ marginBottom: '5px' }}>
      <Grid item xs={1} />
      <Grid item xs={10} textAlign={'center'}>
        <Typography
          gutterBottom
          color="details"
          sx={{ fontSize, display: 'inline-block' }}
        >
          {title}&nbsp;
        </Typography>
        {daysTitle && (
          <Typography
            gutterBottom
            color="details"
            sx={{ fontSize, fontWeight: 'bold', display: 'inline-block' }}
          >
            {daysTitle}&nbsp;
          </Typography>
        )}
        {date && (
          <Typography
            gutterBottom
            color="details"
            sx={{ fontSize, display: 'inline-block' }}
          >
            [ {date} ]
          </Typography>
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default DetailsLine;
