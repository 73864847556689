import React from 'react';
import Typography from '@mui/material/Typography';

const BigText = ({ text }) => {
  return (
    <Typography
      variant="h3"
      component="h1"
      gutterBottom
      color="primary"
      sx={{ fontSize: '20px', fontWeight: 'bold' }}
    >
      {text}
    </Typography>
  );
};

export default BigText;
