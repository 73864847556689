import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { Grid, IconButton, Typography } from '@mui/material';

const HiddenItem = ({ title, handleVisibleClick }) => {
  return (
    <Grid container>
      <Grid item xs={1} />

      <Grid
        item
        xs={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            marginTop: '10px',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'right',
            paddingRight: '20px',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item xs={6} textAlign={'left'} sx={{ paddingLeft: '20px' }}>
        <IconButton color="primary" onClick={handleVisibleClick}>
          <VisibilityIcon />
        </IconButton>
      </Grid>

      <Grid item xs={4} />
      <Grid item xs={4}>
        <div
          style={{
            borderTop: '1px solid gray',
            marginTop: '10px',
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
};

export default HiddenItem;
