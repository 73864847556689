import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid, Paper, Typography } from '@mui/material';

import Button from '../components/ButtonS.jsx';
import DetailsLine from '../components/Details/DetailsLine.jsx';
import DetailsMinMaxLatestLine from '../components/Details/DetailsMinMaxLatestLine.jsx';
import DoneMissed from '../components/Details/DoneMissed.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import TypeNumberDetails from '../components/Details/TypeNumberDetails.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods.js';
import getDaysByDates from '../utils/getDaysByDates.js';
import getDaysTitle from '../utils/getDaysTitle.js';
import Total from '../components/Details/Total.jsx';
import Chart from '../components/Chart.jsx';

import { AppContext } from '../context/app.js';

const stylePaper = {
  padding: '10px',
  width: '300px',
  minHeight: '120px',
};

const Details = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { id, title } = useParams();

  const [actualGraph, setActualGraph] = useState([]);
  const apiRef = useRef({});
  const coefficientRef = useRef(1);
  const itemDescriptionRef = useRef('');

  const [, setError] = useState('');
  const [displayMaxMinSection, setDisplayMaxMinSection] = useState(true);
  const [details, setDetails] = useState();
  const [graph, setGraph] = useState({});
  const [graphTitle, setGraphTitle] = useState(
    <FormattedMessage id="Statistics" />,
  );
  const [latestDate, setLatestDate] = useState();
  const [latestDaysTitle, setLatestDaysTitle] = useState();
  const [loading, setLoading] = useState(true);
  const [perDayTitle, setPerDayTitle] = useState('');
  const [premium, setPremium] = useState(false);
  const [range, setRange] = useState(1);
  const [show, setShow] = useState(true);
  const [selectedYear, setSelectedYear] = useState('2024');
  const [startedDate, setStartedDate] = useState();
  const [startedDaysTitle, setStartedDaysTitle] = useState();
  const [targetDoneConsecutiveDays, setTargetDoneConsecutiveDays] = useState();
  const [targetNotDoneConsecutiveDays, setTargetNotDoneConsecutiveDays] =
    useState();
  const [type, setType] = useState(''); // A value type of the item
  const [visibleArrowRight, setVisibleArrowRight] = useState(false);

  const { defaultWeekDay } = React.useContext(AppContext);

  const toggleRange = async (range, coefficient = 1) => {
    const index = parseInt(`
      ${range}${coefficient > 1 ? coefficient : undefined}
    `);
    setRange(range);
    if (type !== 'text') {
      let graphData = graph[index];
      if (coefficient === 1) setVisibleArrowRight(false);
      if (!graphData || graphData.length === 0) {
        graphData = [];
        setLoading(true);
        if (range === 1) {
          setGraphTitle(`${intl.formatMessage({ id: 'Statistics' })}`);
          let now = new Date().getDay();
          const date7daysAgo = new Date();
          date7daysAgo.setDate(date7daysAgo.getDate() - (7 * coefficient - 1));
          let weekValues = [];
          if (
            defaultWeekDay == 7 ||
            (defaultWeekDay != 7 && now !== defaultWeekDay)
          ) {
            const { values } = await apiRef.current.value.getByDate({
              id,
              date: date7daysAgo,
              daysCount: 15,
            });
            weekValues = values;
          }

          let weekDays = 0;
          let startDate = new Date();
          const shift = (coefficient - 1) * 7;
          startDate.setDate(startDate.getDate() - shift);
          if (defaultWeekDay != 7) {
            while (startDate.getDay() != defaultWeekDay) {
              weekDays += 1;
              startDate.setDate(startDate.getDate() - 1);
              if (weekDays > 10) break;
            }
          } else {
            startDate = date7daysAgo;
          }
          for (let i = 0; i < 7; i++) {
            let total = 0;
            weekValues.reduce((_, val) => {
              if (
                val.createdAt.slice(0, 10) ===
                startDate.toISOString().slice(0, 10)
              ) {
                total += parseInt(val.value);
              }
            }, 0);
            const axiosX = `${startDate.toISOString().slice(8, 10)}.${startDate
              .toISOString()
              .slice(5, 7)}`;
            const perDayTitle = `${intl.formatMessage({ id: 'PerDay' })}`;
            setPerDayTitle(perDayTitle);
            const data = {
              name: axiosX,
              [perDayTitle]: total,
            };
            graphData.push(data);
            startDate.setDate(startDate.getDate() + 1);
          }
          setGraph((prevGraph) => ({
            ...prevGraph,
            [index]: graphData,
          }));
        } else if (range === 2) {
          setGraphTitle(`${intl.formatMessage({ id: 'Statistics' })}`);
          const now = new Date();
          const firstDayOfMonth = new Date(
            now.getFullYear(),
            now.getMonth() - coefficient + 1,
            2,
          );
          const nextMonth = new Date(
            firstDayOfMonth.getFullYear(),
            firstDayOfMonth.getMonth() + 1,
            0,
          );
          const weekDays =
            coefficient > 1 ? nextMonth.getDate() - 1 : now.getDate();
          const { values } = await apiRef.current.value.getByDate({
            id,
            date: firstDayOfMonth,
            daysCount: weekDays,
          });
          for (let i = 0; i < weekDays + 1; i++) {
            let total = 0;
            values.reduce((_, val) => {
              if (
                val.createdAt.slice(0, 10) ===
                firstDayOfMonth.toISOString().slice(0, 10)
              ) {
                total += parseInt(val.value);
              }
            }, 0);
            const axiosX = `${firstDayOfMonth
              .toISOString()
              .slice(8, 10)}.${firstDayOfMonth.toISOString().slice(5, 7)}`;
            const graphTitle = `${intl.formatMessage({ id: 'PerDay' })}`;
            setPerDayTitle(graphTitle);
            const data = {
              name: axiosX,
              [graphTitle]: total,
            };
            graphData.push(data);
            firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
          }
          setGraph((prevGraph) => ({
            ...prevGraph,
            [index]: graphData,
          }));
        } else if (range === 3) {
          setGraphTitle(`
            ${intl.formatMessage({ id: 'Statistics' })} ${selectedYear}
          `);
          const now = new Date();
          const newYearDay = new Date(
            now.getFullYear() - coefficient + 1,
            0,
            2,
          );
          const december31 = new Date(
            new Date().getFullYear() - coefficient + 1,
            0,
            0,
          );
          setSelectedYear(december31.getFullYear());
          const days = Math.floor(
            (new Date() - new Date(december31)) / 86400000,
          );
          const { values } = await apiRef.current.value.getByDate({
            id,
            date: newYearDay,
            daysCount: days,
          });
          const monthCount = coefficient > 1 ? 12 : now.getMonth() + 1;
          for (let i = 1; i <= monthCount; i++) {
            let total = 0;
            const currentMonthDate = new Date(`
              ${now.getFullYear() - coefficient + 1}-${i}-02
            `);
            const currentMonth = currentMonthDate.toISOString().slice(0, 7);
            values.reduce((_, val) => {
              if (val.createdAt.slice(0, 7) === currentMonth) {
                total += parseInt(val.value);
              }
            }, 0);
            const axiosX = `${currentMonthDate.toISOString().slice(5, 7)}`;
            const graphTitle = `${intl.formatMessage({
              id: 'PerMonthForDetailsPage',
            })}`;
            setPerDayTitle(graphTitle);
            const data = {
              name: axiosX,
              [graphTitle]: total,
            };
            graphData.push(data);
          }
          setGraph((prevGraph) => ({
            ...prevGraph,
            [index]: graphData,
          }));
        }
        setActualGraph(graphData);
      } else {
        if (coefficient === 1) setVisibleArrowRight(false);
        if (range === 3) {
          let year = new Date().getFullYear();
          if (coefficient > 1) year = year - coefficient + 1;
          console.log(coefficient, year);
          const graphTitle = `${intl.formatMessage({
            id: 'PerMonthForDetailsPage',
          })}`;
          setPerDayTitle(graphTitle);
          setGraphTitle(`
            ${intl.formatMessage({ id: 'Statistics' })} ${year}`);
          setSelectedYear(year);
        } else if (range === 1 || range === 2) {
          setGraphTitle(`${intl.formatMessage({ id: 'Statistics' })}`);
          const graphTitle = `${intl.formatMessage({
            id: 'PerDay',
          })}`;
          setPerDayTitle(graphTitle);
        }
        setActualGraph(graph[index]);
      }
    }
    coefficientRef.current = coefficient;
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      setTargetDoneConsecutiveDays(
        `${intl.formatMessage({ id: 'TargetDone' })} ${intl.formatMessage({
          id: 'ConsecutiveDays',
        })}`,
      );
      setTargetNotDoneConsecutiveDays(
        `${intl.formatMessage({ id: 'TargetNotDone' })} ${intl.formatMessage({
          id: 'ConsecutiveDays',
        })}`,
      );

      const api = await fetchApiMethods();
      apiRef.current = api;

      const { status } = await api.user.getStatus();
      if (status.premium) setPremium(true);
      const { item } = await api.item.get({ id });
      const { detailsList } = await api.item.getDetails({
        itemIds: [id],
        full: true,
      });
      itemDescriptionRef.current = item.description;

      const currentDetails =
        item.valueType === 'text'
          ? detailsList.find((d) => d.title === title)
          : detailsList && detailsList.length === 1 && detailsList[0];

      if (item.valueType === 'text') setShow(false);

      if (currentDetails) {
        const notDisplayMaxMinSection =
          currentDetails.min === currentDetails.minPerDay &&
          currentDetails.max === currentDetails.maxPerDay;
        setDisplayMaxMinSection(!notDisplayMaxMinSection);

        setDetails(currentDetails);
        if (currentDetails.startedAt) {
          const startedDate = new Date(currentDetails.startedAt);
          const startedDays = getDaysByDates(startedDate, new Date());
          const daysTitle = getDaysTitle(startedDays, intl);
          if (daysTitle)
            setStartedDaysTitle(
              `${daysTitle} ${intl.formatMessage({ id: 'Ago' })}`,
            );
          setStartedDate(startedDate.toLocaleDateString());
        }
        if (currentDetails.latestAt) {
          const latestDate = new Date(currentDetails.latestAt);
          const latestDays = getDaysByDates(latestDate, new Date());
          const daysTitle = getDaysTitle(latestDays, intl);
          if (daysTitle)
            setLatestDaysTitle(
              `${daysTitle} ${intl.formatMessage({ id: 'Ago' })}`,
            );
          setLatestDate(latestDate.toLocaleDateString());
        }
      }

      setType(item.valueType);
      await toggleRange(1); // Week by default each time

      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Grid container spacing={1}>
      <Title text={title} />
      <Grid item xs={12} textAlign={'center'}>
        <Typography gutterBottom>{itemDescriptionRef.current}</Typography>
      </Grid>
      {!details ? (
        <>
          <Grid item xs={2} />
          <Grid item xs={8} textAlign={'center'}>
            {<FormattedMessage id="NoDetails" />}
          </Grid>
          <Grid item xs={2} />
        </>
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          marginTop={'0px'}
        >
          {premium && show && (
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              marginTop={'0px'}
            >
              <Grid item>
                <Chart
                  coefficient={coefficientRef.current}
                  title={graphTitle}
                  data={actualGraph}
                  perDayTitle={perDayTitle}
                  range={range}
                  toggleRange={toggleRange}
                  visibleArrowRight={visibleArrowRight}
                  setVisibleArrowRight={setVisibleArrowRight}
                />
              </Grid>
            </Grid>
          )}
          <DetailsLine
            title={<FormattedMessage id="Started" />}
            daysTitle={startedDaysTitle}
            date={startedDate}
          />
          <DetailsLine
            title={<FormattedMessage id="Latest" />}
            daysTitle={latestDaysTitle}
            date={latestDate}
          />
          <Total total={details.total} />
          {(details.daysMinStrike > 0 ||
            details.daysMaxStrike > 0 ||
            details.daysLatestStrike > 0) && (
            <Grid item>
              <Paper elevation={7} style={stylePaper}>
                <DetailsMinMaxLatestLine
                  title={<FormattedMessage id="DoneConsecutiveDays" />}
                  min={details.daysMinStrike}
                  dateMin={new Date(
                    details.dateDaysMinStrike,
                  ).toLocaleDateString()}
                  colorMin="min"
                  max={details.daysMaxStrike}
                  dateMax={new Date(
                    details.dateDaysMaxStrike,
                  ).toLocaleDateString()}
                  colorMax="max"
                  latest={details.daysLatestStrike}
                  dateLatest={new Date(
                    details.dateDaysLatestStrike,
                  ).toLocaleDateString()}
                />
              </Paper>
            </Grid>
          )}
          <Grid item>
            <Paper elevation={7} style={stylePaper}>
              <DetailsMinMaxLatestLine
                title={<FormattedMessage id="MissedConsecutiveDays" />}
                min={details.daysMinDelay}
                dateMin={new Date(
                  details.dateDaysMinDelay,
                ).toLocaleDateString()}
                colorMin="max"
                max={details.daysMaxDelay}
                dateMax={new Date(
                  details.dateDaysMaxDelay,
                ).toLocaleDateString()}
                colorMax="min"
                latest={details.daysLatestDelay}
                dateLatest={new Date(
                  details.dateDaysLatestDelay,
                ).toLocaleDateString()}
              />
            </Paper>
          </Grid>
          {(details.daysMinTargetStrike > 0 ||
            details.daysMaxTargetStrike > 0 ||
            details.daysLatestTargetStrike > 0) && (
            <Grid item>
              <Paper elevation={7} style={stylePaper}>
                <DetailsMinMaxLatestLine
                  title={targetDoneConsecutiveDays}
                  min={details.daysMinTargetStrike}
                  dateMin={new Date(
                    details.dateDaysMinTargetStrike,
                  ).toLocaleDateString()}
                  colorMin="min"
                  max={details.daysMaxTargetStrike}
                  dateMax={new Date(
                    details.dateDaysMaxTargetStrike,
                  ).toLocaleDateString()}
                  colorMax="max"
                  latest={details.daysLatestTargetStrike}
                  dateLatest={new Date(
                    details.dateDaysLatestTargetStrike,
                  ).toLocaleDateString()}
                />
              </Paper>
            </Grid>
          )}
          {(details.daysMinTargetDelay > 0 ||
            details.daysMaxTargetDelay > 0 ||
            details.daysLatestTargetDelay > 0) && (
            <Grid item>
              <Paper elevation={7} style={stylePaper}>
                <DetailsMinMaxLatestLine
                  title={targetNotDoneConsecutiveDays}
                  min={details.daysMinTargetDelay}
                  dateMin={new Date(
                    details.dateDaysMinTargetDelay,
                  ).toLocaleDateString()}
                  colorMin="max"
                  max={details.daysMaxTargetDelay}
                  dateMax={new Date(
                    details.dateDaysMaxTargetDelay,
                  ).toLocaleDateString()}
                  colorMax="min"
                  latest={details.daysLatestTargetDelay}
                  dateLatest={new Date(
                    details.dateDaysLatestTargetDelay,
                  ).toLocaleDateString()}
                />
              </Paper>
            </Grid>
          )}
          {(details.minPerDay > 0 || details.maxPerDay > 0) && (
            <Grid item>
              <Paper
                elevation={7}
                style={{
                  padding: '10px',
                  width: '300px',
                }}
              >
                <TypeNumberDetails
                  minTitle={'MinimumPerDay'}
                  maxTitle={'MaximumPerDay'}
                  min={details.minPerDay}
                  dateMin={new Date(details.dateMinPerDay).toLocaleDateString()}
                  max={details.maxPerDay}
                  dateMax={new Date(details.dateMaxPerDay).toLocaleDateString()}
                />
              </Paper>
            </Grid>
          )}
          {displayMaxMinSection ? (
            <Grid item>
              <Paper elevation={7} style={stylePaper}>
                <TypeNumberDetails
                  minTitle={'Minimum'}
                  maxTitle={'Maximum'}
                  min={details.min}
                  dateMin={new Date(details.dateMin).toLocaleDateString()}
                  max={details.max}
                  dateMax={new Date(details.dateMax).toLocaleDateString()}
                />
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
          {(details.daysTargetMissed > 0 || details.daysTargetDone > 0) && (
            <Grid item>
              <Paper elevation={7} style={stylePaper}>
                <DoneMissed
                  titleMissed={<FormattedMessage id="TargetNotDone" />}
                  valueMissed={details.daysTargetMissed}
                  titleDone={<FormattedMessage id="TargetDone" />}
                  valueDone={details.daysTargetDone}
                />
              </Paper>
            </Grid>
          )}
          <Grid item>
            <Paper elevation={7} style={stylePaper}>
              <DoneMissed
                titleMissed={<FormattedMessage id="Missed" />}
                valueMissed={details.daysMissed}
                titleDone={<FormattedMessage id="Done" />}
                valueDone={details.daysDone}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        textAlign={'center'}
        marginTop={'20px'}
        marginBottom={'20px'}
      >
        <Button onClick={() => navigate('/today')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Details;
