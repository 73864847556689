/* eslint-disable no-undef */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import SendIcon from '@mui/icons-material/Send';

import BigText from '../components/BigText.jsx';
import Error from '../components/Error.jsx';
import Input from '../components/Input/Input.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons.jsx';

import isValidEmail from '../shared/utils/validateEmail.js';
import getLocalTimeZone from '../shared/utils/getLocalTimeZone.js';
import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods.js';

import { AuthContext } from '../context/auth.js';
import { UserContext } from '../context/user.js';

const Login = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const [api, setApi] = useState({});
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [visibleReset, setVisibleReset] = useState(false);
  const { date } = useContext(UserContext);
  const attemptRef = useRef(0);

  const fetchData = async () => {
    const api = await fetchApiMethods();
    setApi(api);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleResetPassword = async () => {
    setError('');
    setErrorEmail('');
    try {
      setLoading(true);
      if (!isValidEmail(email))
        setErrorEmail(<FormattedMessage id="EmailInvalid" />);
      let { user } = await api.user.find({ email });
      if (!user) {
        setError(<FormattedMessage id="EmailNotFound" />);
        setLoading(false);
        return;
      }
      if (user.token === null) {
        const { token } = await api.user.getUrlToken();
        const url = `${process.env.REACT_APP_HOST}/reset/${token}`;
        console.log('url', url); // DEBUG Information for testing purpose
        await api.user.sendEmail({
          clientId: user.id,
          email,
          url,
          token,
          type: 'reset',
          inputLocale: localStorage.getItem('selectedLocale'),
        });
      }
      setIsDone(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setErrorEmail('');
    setErrorPassword('');

    try {
      if (!email) setErrorEmail(<FormattedMessage id="RequiredField" />);
      if (!password) setErrorPassword(<FormattedMessage id="RequiredField" />);
      if (!isValidEmail(email))
        setErrorEmail(<FormattedMessage id="EmailInvalid" />);

      if (!email || !password || !isValidEmail(email)) return;
      let { user } = await api.user.find({ email });
      if (!user) {
        setError(<FormattedMessage id="IncorrectLoginInfo" />);
        setLoading(false);
        return;
      }
      if (user.token !== null) {
        setLoading(false);
        setIsDone(true);
        return;
      }

      setLoading(true);
      attemptRef.current += 1;
      if (attemptRef.current > 1) setVisibleReset(true);
      const { token } = await api.auth.login({ email, password });
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);
      const locale = localStorage.getItem('selectedLocale');
      await api.user.update({ locale });
      await api.user.setTimeZone({ timeZone: getLocalTimeZone(date) });
      setIsAuth(true);
      setLoading(false);
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      setLoading(false);
      if (errorMessage === 'Incorrect Login or Password')
        setError(<FormattedMessage id="IncorrectLoginInfo" />);
      else setError(errorMessage);
    }
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="LoginPage" />} />
      {isDone ? (
        <Grid item xs={12} textAlign="center">
          <BigText text={<FormattedMessage id="EmailCheck" />} />
          <Error text={<FormattedMessage id="SpamFolder" />} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} textAlign="center">
            <Input
              id="login"
              label={<FormattedMessage id="EmailAddress" />}
              value={email}
              handleError={errorEmail}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Input
              id="loginPassword"
              label={<FormattedMessage id="Password" />}
              type="password"
              value={password}
              handleError={errorPassword}
              onChange={handlePasswordChange}
            />
          </Grid>
          {visibleReset && (
            <Grid item xs={12} textAlign="center">
              <Link
                component="button"
                onClick={handleResetPassword}
                color="primary"
                target="_blank"
              >
                <FormattedMessage id="ResetPassword" />
              </Link>
            </Grid>
          )}
          <Grid item xs={12} textAlign="center">
            <TwoButtons
              leftTitle={<FormattedMessage id="Back" />}
              handleLeftClick={() => navigate('/menu')}
              rightTitle={<FormattedMessage id="SignIn" />}
              handleRightClick={handleSubmit}
              loading={loading}
              endIcon={<SendIcon />}
              loadingPosition="end"
            />
          </Grid>
        </>
      )}

      {error && <Error text={error} />}
    </Grid>
  );
};

export default Login;
