import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';

import './DateForm.css';

function DateForm({ visible, setVisible, handleModalClick }) {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const handleClose = () => {
    setVisible(false);
  };

  const [day, setDay] = useState(parseInt(currentDay));
  const [month, setMonth] = useState(parseInt(currentMonth));
  const [year, setYear] = useState(parseInt(currentYear));

  const handleDecrease = (field) => {
    switch (field) {
      case 'day':
        setDay((prevDay) => Math.max(1, prevDay - 1));
        break;
      case 'month':
        setMonth((prevMonth) => Math.max(1, prevMonth - 1));
        break;
      case 'year':
        setYear((prevYear) => Math.max(1970, prevYear - 1));
        break;
      default:
        break;
    }
  };

  const handleIncrease = (field) => {
    switch (field) {
      case 'day':
        setDay((prevDay) => Math.min(31, prevDay + 1));
        break;
      case 'month':
        setMonth((prevMonth) => Math.min(12, prevMonth + 1));
        break;
      case 'year':
        setYear((prevYear) => Math.min(new Date().getFullYear(), prevYear + 1));
        break;
      default:
        break;
    }
    const selectedDate = new Date(year, month - 1, day);
    if (selectedDate.getTime() > currentDate.getTime()) {
      setDay(currentDay);
      setMonth(currentMonth);
      setYear(currentYear);
    }
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage id="GoToDate" />
      </DialogTitle>
      <DialogContent className="date-form-content">
        <div>
          <Button
            className="arrow-button"
            onClick={() => handleIncrease('day')}
          >
            +
          </Button>
          <div className="date-value">{day}</div>
          <Button
            className="arrow-button"
            onClick={() => handleDecrease('day')}
          >
            -
          </Button>
        </div>
        <div>
          <Button
            className="arrow-button"
            onClick={() => handleIncrease('month')}
          >
            +
          </Button>
          <div className="date-value">{month}</div>
          <Button
            className="arrow-button"
            onClick={() => handleDecrease('month')}
          >
            -
          </Button>
        </div>
        <div>
          <Button
            className="arrow-button"
            onClick={() => handleIncrease('year')}
          >
            +
          </Button>
          <div className="date-value">{year}</div>
          <Button
            className="arrow-button"
            onClick={() => handleDecrease('year')}
          >
            -
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const selectedDate = new Date(year, month - 1, day);
            handleModalClick(selectedDate);
          }}
        >
          <FormattedMessage id="GoTo" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateForm;
