/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import AgreementText from '../components/AgreementText.jsx';
import BigText from '../components/BigText.jsx';
import Button from '../components/ButtonS.jsx';
import Input from '../components/Input/Input.jsx';
import Title from '../components/Title.jsx';
import TwoButtons from '../components/TwoButtons.jsx';
import errorMessageHandler from '../shared/utils/errorMessageHandler.js';
import isValidEmail from '../shared/utils/validateEmail.js';
import { fetchApiMethods } from '../api/getMethods.js';
import { useTheme } from '@mui/material/styles';
import { getStyles } from '../styles.js';
import DynamicFontText from '../components/DynamicFontText.jsx';

const Register = () => {
  const theme = useTheme();
  const pStyles = getStyles(theme);

  const navigate = useNavigate();
  const { locale } = useParams();
  if (locale) localStorage.setItem('selectedLocale', locale);

  const [api, setApi] = useState({});
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState();

  const handleCodeChange = (event) => setCode(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleNextClick = async () => {
    setError('');
    if (!email) {
      setError(<FormattedMessage id="RequiredField" />);
      return;
    }
    if (isValidEmail(email)) {
      try {
        const api = await fetchApiMethods();
        setApi(api);
        let { user } = await api.user.find({ email });
        if (user && user.password !== null) {
          setError(<FormattedMessage id="EmailInUse" />);
        } else {
          setToken(user && user.token); // Just in case, possible to remove "token" state
          if (!user) {
            const { token } = await api.user.getUrlToken();
            setToken(token);
            const locale = localStorage.getItem('selectedLocale');
            const userParams = { email, token };
            if (locale) userParams.locale = locale;
            user = await api.user.create(userParams);
          }
          console.log('user.id', user.id);
          await api.user.registerWithCode({ id: user.id, email });
          setUserId(user.id);
          setIsDone(true);
        }
      } catch (error) {
        console.log(error);
        setError(errorMessageHandler(error));
      }
    } else {
      setError(<FormattedMessage id="EmailInvalid" />);
      return;
    }
  };
  const handleVerifyClick = async () => {
    // console.log('userId', userId, 'code', code, 'token', token);
    const result = await api.user.verifyCode({ clientId: userId, code, token });
    if (result?.verificationResult) navigate(`/reset/${token}`);
    else setErrorCode(<FormattedMessage id="CodeIncorrect" />);
  };

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="CreateProfile" />} />
      {isDone ? (
        <>
          <Grid item xs={12} textAlign="center">
            <BigText text={<FormattedMessage id="EnterCode" />} />
            <DynamicFontText
              text={<FormattedMessage id="SpamFolder" />}
              color={theme.palette.premium}
              fontSize="12px"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Input
              id="code"
              label={<FormattedMessage id="Code" />}
              value={code}
              handleError={errorCode}
              onChange={handleCodeChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button onClick={handleVerifyClick}>
              <FormattedMessage id="Confirm" />
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={1} />
          <Grid item xs={10} textAlign={'center'}>
            <Box>
              <Typography sx={pStyles.registerEmailInfo.message}>
                <FormattedMessage id="RegisterText1_guarantee_delivery" />
                {' \n'}
                <Typography
                  component="span"
                  sx={pStyles.registerEmailInfo.emailText}
                >
                  {process.env.REACT_APP_SOURCE_EMAIL}
                </Typography>{' '}
                <FormattedMessage id="RegisterText2_contact_list_spam" />
              </Typography>
              <Typography sx={pStyles.registerEmailInfo.message}>
                <FormattedMessage id="RegisterText3_After_adding_email" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={12} textAlign="center">
            <Input
              id="createProfile"
              label={<FormattedMessage id="EmailAddress" />}
              handleError={error}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <TwoButtons
              leftTitle={<FormattedMessage id="Back" />}
              handleLeftClick={() => {
                navigate('/home');
              }}
              rightTitle={<FormattedMessage id="Next" />}
              handleRightClick={handleNextClick}
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <AgreementText />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Register;
