import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';
import SubItem from '../components/SubItem.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler.js';

import { fetchApiMethods } from '../api/getMethods.js';

const SubItemPage = () => {
  const navigate = useNavigate();
  const { id, title } = useParams();

  const [, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [subItems, setSubItems] = useState([]);

  const fetchData = async () => {
    try {
      const api = await fetchApiMethods();
      const { detailsList } = await api.item.getDetails({ itemIds: [id] });
      setSubItems(detailsList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={title} />

      {subItems && subItems.length ? (
        <Grid item xs={12} textAlign="center">
          <div style={{ height: '70vh', overflow: 'auto' }}>
            {subItems.map((item) => (
              <SubItem key={item.id} details={item} />
            ))}
          </div>
        </Grid>
      ) : (
        <Title color="gray" text={<FormattedMessage id="NoItems" />} />
      )}

      <Grid item xs={12} textAlign={'center'}>
        <Button onClick={() => navigate('/today')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubItemPage;
