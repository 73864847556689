/* eslint-disable no-undef */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const AgreementText = () => {
  const locale = localStorage.getItem('selectedLocale');
  const [urlTerms] = useState(`/resources/${locale}/terms.html`);
  const [urlPolicy] = useState(`/resources/${locale}/policy.html`);

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <FormattedMessage id="Agree" />{' '}
      <Link href={urlTerms} color="primary" target="_blank">
        <FormattedMessage id="TermsOfService" />
      </Link>
      <FormattedMessage id="And" />
      <Link href={urlPolicy} color="primary" target="_blank">
        <FormattedMessage id="PrivacyPolicy" />
      </Link>
      .
    </Typography>
  );
};

export default AgreementText;
