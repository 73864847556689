const button = (theme) => ({
  variant: 'contained',
  sx: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    minWidth: '160px',
  },
});

const gridCenter = {
  display: 'flex',
  justifyContent: 'center',
};

const gridRight = {
  display: 'flex',
  justifyContent: 'right',
};

const gridTitle = {
  marginTop: '20px',
  marginBottom: '30px',
};

const paddingRight = {
  paddingRight: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
};

const paddingLeft = {
  paddingLeft: '10px',
  display: 'flex',
  justifyContent: 'flex-start',
};

const stylePaper = {
  padding: '10px',
  width: '300px',
  minHeight: '120px',
};

// eslint-disable-next-line no-undef
module.exports = {
  button,
  gridCenter,
  gridTitle,
  gridRight,
  paddingRight,
  paddingLeft,
  stylePaper,
};
