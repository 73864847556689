import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ComboBox = ({ title, options, value, handleComboBoxChange }) => {
  const id = `select-${title}-small`;
  const labelId = `select-${title}`;
  return (
    <FormControl sx={{ m: 1, minWidth: 297 }} size="small">
      <InputLabel id="combo-box">{title}</InputLabel>
      <Select
        id={id}
        labelId={labelId}
        value={value}
        label={title}
        onChange={handleComboBoxChange}
        MenuProps={{
          sx: {
            '& .MuiMenuItem-root.Mui-selected': {
              fontWeight: 'bold',
              fontSize: '16px',
            },
          },
        }}
      >
        <MenuItem value="">
          <em>{<FormattedMessage id="None" />}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ComboBox;
