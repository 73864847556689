import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Error from '../components/Error.jsx';
import Input from '../components/Input/Input.jsx';
import Title from '../components/Title.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler';
import { fetchApiMethods } from '../api/getMethods.js';
import BigText from '../components/BigText.jsx';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [api, setApi] = useState({});
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(false);

  const handleConfirmPasswordChange = (event) =>
    setConfirmPassword(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleSubmit = async (event) => {
    setErrorPassword('');
    setErrorConfirmPassword('');
    event.preventDefault();

    if (!password) setErrorPassword(<FormattedMessage id="RequiredField" />);
    if (!confirmPassword)
      setErrorConfirmPassword(<FormattedMessage id="RequiredField" />);
    if (!password || !confirmPassword) return;

    if (password !== confirmPassword) {
      setErrorPassword(<FormattedMessage id="PasswordNotMatching" />);
      setErrorConfirmPassword(<FormattedMessage id="PasswordNotMatching" />);
      return;
    }

    try {
      await api.auth.register({ id: user.id, password });
      navigate(`/login`);
    } catch (error) {
      setError(errorMessageHandler(error));
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        const { user } = await api.user.find({ token });
        if (user) setUser(user);
        setApi(api);
      } catch (error) {
        setError(errorMessageHandler(error));
      }
    }
    fetchData();
  }, []);

  if (user) {
    return (
      <Grid container spacing={2}>
        <Title text={<FormattedMessage id="ResetPasswordForm" />} />
        <Grid item xs={12} textAlign="center">
          <Input
            id="userPassword"
            label={<FormattedMessage id="Password" />}
            type="password"
            value={password}
            handleError={errorPassword}
            onChange={handlePasswordChange}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Input
            id="userConfirmPassword"
            label={<FormattedMessage id="ConfirmPassword" />}
            type="password"
            value={confirmPassword}
            handleError={errorConfirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button onClick={handleSubmit}>
            {<FormattedMessage id="Finish" />}
          </Button>
        </Grid>
        {error && <Error text={error} />}
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Title text={<FormattedMessage id="ResetPasswordForm" />} />
        <Grid item xs={12} textAlign="center">
          <BigText text={<FormattedMessage id="ExpiredLink" />} />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            onClick={() => {
              navigate('/home');
            }}
          >
            {<FormattedMessage id="Home" />}
          </Button>
        </Grid>
        {error && <Error text={error} />}
      </Grid>
    );
  }
};

export default ResetPassword;
