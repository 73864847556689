import React from 'react';
import Typography from '@mui/material/Typography';

const DynamicFontText = ({ text, color, fontSize = '20px' }) => {
  return (
    <>
      <Typography
        variant="h3"
        component="h1"
        color={color || 'primary  '}
        sx={{ fontSize, fontWeight: 'bold' }}
      >
        {text}
      </Typography>
    </>
  );
};

export default DynamicFontText;
