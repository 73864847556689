import React from 'react';
import Button from './ButtonS.jsx';

import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import VisibilityIcon from '@mui/icons-material/Visibility';

const TwoButtons = ({
  leftTitle,
  handleLeftClick,
  rightTitle,
  handleRightClick,
  gap = 20,
  iconButton = false,
  iconButtonHandler,
  iconButtonTitle,
  disabledRight,
  disabledLeft,
  ...props
}) => {
  const extraStyle = {
    marginLeft: `${gap}px`,
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        disabled={disabledLeft || props.loading ? true : false}
        onClick={handleLeftClick}
      >
        {leftTitle}
      </Button>
      {iconButton ? (
        <Tooltip title={iconButtonTitle}>
          <IconButton
            color="primary"
            style={extraStyle}
            onClick={iconButtonHandler}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <Button
        disabled={disabledRight}
        typeLoading={true}
        {...props}
        onClick={handleRightClick}
        style={extraStyle}
      >
        {rightTitle}
      </Button>
    </div>
  );
};

export default TwoButtons;
