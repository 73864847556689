/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import ReactGA from 'react-ga';

import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './themes/dark.js';
import lightTheme from './themes/light.js';

import '@formatjs/intl-relativetimeformat/locale-data/uk';
import { IntlProvider } from 'react-intl';
import en from './shared/intl/en.js';
import ru from './shared/intl/ru.js';
import uk from './shared/intl/uk.js';
const messages = { en, ru, uk };

import { AppContext } from './context/app';

const Root = () => {
  const [themeTitle, setThemeTitle] = useState('lightTheme');
  const [theme, setTheme] = useState(lightTheme);
  const [locale, setLocale] = useState('en');
  const [defaultWeekDay, setDefaultWeekDay] = useState('0'); // 1 - mon, 6 - sat, 0 - sun, 8 - days

  const toggleTheme = (theme) => {
    const THEMES = { darkTheme, lightTheme };
    localStorage.setItem('selectedTheme', theme);
    setTheme(THEMES[theme]);
    setThemeTitle(theme);
  };

  const toggleLocale = async (locale) => {
    localStorage.setItem('selectedLocale', locale);
    setLocale(locale);
  };

  const toggleDefaultDayOfWeek = async (day) => {
    localStorage.setItem('selectedDefaultWeekDay', day);
    setDefaultWeekDay(day);
  };

  // ReactGA.initialize('G-MFLB6PPS0V');

  useEffect(() => {
    const storedTheme = localStorage.getItem('selectedTheme');
    const storedLocale = localStorage.getItem('selectedLocale');
    const storedDefaultWeekDay = localStorage.getItem('selectedDefaultWeekDay');

    toggleTheme(storedTheme ? storedTheme : 'lightTheme');
    toggleLocale(storedLocale ? storedLocale : 'en');

    if (storedLocale) {
      const defaultDay = storedLocale == 'en' ? '0' : '1';
      toggleDefaultDayOfWeek(
        storedDefaultWeekDay ? storedDefaultWeekDay : defaultDay,
      );
    }

    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <AppContext.Provider
          value={{
            themeTitle,
            toggleTheme,
            locale,
            toggleLocale,
            defaultWeekDay,
            toggleDefaultDayOfWeek,
          }}
        >
          <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: theme.palette.background.default }}>
              <App />
            </div>
          </ThemeProvider>
        </AppContext.Provider>
      </IntlProvider>
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
