import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({ handleEditClick }) => {
  return (
    <IconButton
      sx={{ borderRadius: '50%', width: 48, height: 48 }}
      onClick={handleEditClick}
    >
      <EditIcon sx={{ color: 'green' }} />
    </IconButton>
  );
};

export default EditButton;
