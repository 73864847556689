import React from 'react';

import { Typography } from '@mui/material';

const SectionTitle = ({ title }) => {
  return (
    <Typography
      color="primary"
      style={{ marginTop: '7px', marginBottom: '3px', fontWeight: 'bold' }}
    >
      {title}
    </Typography>
  );
};

export default SectionTitle;
