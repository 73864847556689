import React from 'react';

import About from '../pages/About.jsx';
import Details from '../pages/Details.jsx';
import ErrorPage from '../pages/ErrorPage.jsx';
import Export from '../pages/Export.jsx';
import Home from '../pages/Home.jsx';
import Import from '../pages/Import.jsx';
import ItemEditing from '../pages/ItemEditing.jsx';
import Item from '../pages/Item.jsx';
import Login from '../pages/Login.jsx';
import Menu from '../pages/Menu.jsx';
import Payments from '../pages/Payments.jsx';
import Profile from '../pages/Profile.jsx';
import Register from '../pages/Register.jsx';
import ResetPassword from '../pages/ResetPassword.jsx';
import Sections from '../pages/Sections.jsx';
import Settings from '../pages/Settings.jsx';
import Today from '../pages/Today.jsx';
import Value from '../pages/Value.jsx';
import HiddenItems from '../pages/HiddenItems.jsx';
import SubItemPage from '../pages/SubItemPage.jsx';

export const publicRoutes = [
  { path: '/error', element: <ErrorPage /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/register/:locale', element: <Register /> },
  { path: '/reset/:token', element: <ResetPassword /> },
  { path: '*', element: <Home /> },
];

export const privateRoutes = [
  { path: '/about', element: <About /> },
  { path: '/detail/:id/:title', element: <Details /> },
  { path: '/error', element: <ErrorPage /> },
  { path: '/error/:message', element: <ErrorPage /> },
  { path: '/edit-item', element: <ItemEditing /> },
  { path: '/edit-item/:id', element: <ItemEditing /> },
  { path: '/export', element: <Export /> },
  { path: '/hidden', element: <HiddenItems /> },
  { path: '/import', element: <Import /> },
  { path: '/items/:id', element: <Item /> },
  { path: '/payments', element: <Payments /> },
  { path: '/profile', element: <Profile /> },
  { path: '/sections/:id', element: <Sections /> },
  { path: '/settings', element: <Settings /> },
  { path: '/subitems/:id/:title', element: <SubItemPage /> },
  { path: '/today', element: <Today /> },
  { path: '/values/:itemId/:itemTitle/:valueType/:form/', element: <Value /> },
  {
    path: '/values/:itemId/:itemTitle/:valueType/:form/:createdAt',
    element: <Value />,
  },
  {
    path: '/values/value/:itemId/:itemTitle/:valueType/:valueId',
    element: <Value />,
  },
  { path: '*', element: <Menu /> },
];
