/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Container, Card, CardContent, Grid, Typography } from '@mui/material';

import TwoButtons from '../components/TwoButtons.jsx';
import DynamicFontText from '../components/DynamicFontText.jsx';
import Loader from '../components/Loader.jsx';
import Title from '../components/Title.jsx';

import errorMessageHandler from '../shared/utils/errorMessageHandler';

import css from '../themes/styles.js';

import { fetchApiMethods } from '../api/getMethods.js';

const PRICE_USD = process.env.REACT_APP_PRICE_USD || 5;
const REDIRECT_URL = process.env.REACT_APP_PAYMENT_URL;

const Payments = () => {
  const navigate = useNavigate();

  const commonFontSize = '16px';

  const [, setApi] = useState({});
  const [email] = useState(localStorage.getItem('email'));
  const [loading, setLoading] = useState(false);
  const [, setError] = useState('');

  const headItemStyle = { fontWeight: 'bold', fontSize: '14px' };
  const itemStyle = { fontSize: '13px', paddingLeft: '20px' };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const api = await fetchApiMethods();
        setApi(api);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        navigate(`/error`);
      }
    }
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();
      // TODO TEMP IMPLEMENTATION
      // const userEmail = localStorage.getItem('email');
      // if (userEmail === '') {
      window.location.href = REDIRECT_URL; // LINE IN LIVE VERSION
      // } else {
      //   setLoading(false);
      //   alert(
      //     'PREMIUM FUNCTIONALITY TEMPORARY UNAVAILABLE',
      //   );
      // }
    } catch (error) {
      setLoading(false);
      navigate(`/error`);
      setError(errorMessageHandler(error));
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title
        text={<FormattedMessage id="Unlock" />}
        extraText={<FormattedMessage id="Premium" />}
        extraColor="premium"
      />
      <Grid item xs={6} sx={css.paddingRight}>
        <DynamicFontText color="premium" text={`${PRICE_USD} $`} />
      </Grid>
      <Grid item xs={6} sx={css.paddingLeft}>
        <DynamicFontText
          color="primary"
          text={<FormattedMessage id="PerMonth" />}
        />
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex ' }}
      >
        <DynamicFontText
          text={<FormattedMessage id="Email" />}
          fontSize={commonFontSize}
        />
        &nbsp;
        <DynamicFontText text={email} fontSize={commonFontSize} />
      </Grid>
      <Container style={{ padding: '20px 5px 20px 28px', maxWidth: '700px' }}>
        <Card elevation={7}>
          <CardContent>
            <Typography variant="h6" gutterBottom textAlign={'center'}>
              {<FormattedMessage id="Benefits" />}
            </Typography>
            <Grid item xs={12} sx={headItemStyle}>
              {<FormattedMessage id="BenefitsExchangeTitle" />}
            </Grid>
            <Grid item xs={12} sx={itemStyle}>
              {<FormattedMessage id="BenefitsExchangeDescription" />}
            </Grid>

            <Grid item xs={12} sx={headItemStyle}>
              {<FormattedMessage id="BenefitsInstantStatisticsUpdate" />}
            </Grid>
            <Grid item xs={12} sx={itemStyle}>
              {<FormattedMessage id="BenefitsInstantStatisticDescription" />}
            </Grid>

            <Grid item xs={12} sx={headItemStyle}>
              {<FormattedMessage id="BenefitsEarlyAccess" />}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Grid item xs={12} textAlign={'center'}>
        <TwoButtons
          leftTitle={<FormattedMessage id="Back" />}
          handleLeftClick={() => {
            navigate('/profile');
          }}
          rightTitle={<FormattedMessage id="Purchase" />}
          handleRightClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default Payments;
