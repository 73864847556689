import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#18c615bd',
    },
    secondary: {
      main: '#005329',
    },
    max: '#32ff3d',
    maxLight: '#32ff3d',
    min: '#fff600',
    minLight: '#fff600',
    red: '#ff0000',
    premium: '#c700ff',
    over: '#04d11c',
    undone: '#5b5b5b',
  },
});

export default darkTheme;
