const getDaysTitle = (days, intl) => {
  if (days === 0) return undefined;

  let messageId;
  if (days === 1) {
    messageId = 'Day';
  } else if (days % 10 === 1 && days % 100 !== 11) {
    messageId = 'Day';
  } else if (
    days % 10 >= 2 &&
    days % 10 <= 4 &&
    (days % 100 < 10 || days % 100 >= 20)
  ) {
    messageId = 'Days2';
  } else {
    messageId = 'Days';
  }

  return days + ' ' + intl.formatMessage({ id: messageId });
};

export default getDaysTitle;
