export const getStyles = (theme) => {
  return {
    registerEmailInfo: {
      emailText: {
        fontWeight: 'bold',
        color: theme.palette.premium,
      },
      message: {
        paddingHorizontal: 80,
        fontSize: 19,
        marginBottom: 2,
        textAlign: 'center',
      },
    },
  };
};
