import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS.jsx';
import Error from '../components/Error.jsx';
import Title from '../components/Title.jsx';
import BigText from '../components/BigText.jsx';

const ErrorPage = () => {
  const navigate = useNavigate();
  const { message } = useParams();
  return (
    <Grid container spacing={2} textAlign={'center'}>
      <Title color="red" text={<FormattedMessage id="Error" />} />
      <Grid item xs={2} />
      <Grid item xs={8}>
        <BigText
          text={
            <FormattedMessage id="ErrorBody" color={'gray'} textSize={'24px'} />
          }
        />
      </Grid>
      <Grid item xs={2} />

      {message && (
        <Grid item xs={12}>
          <Error text={message} />
        </Grid>
      )}

      <Grid item xs={12} textAlign="center">
        <Button onClick={() => navigate('/menu')}>
          {<FormattedMessage id="Menu" />}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
