import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { button } from '../themes/styles.js';

const ButtonS = ({ typeLoading = false, children, ...props }) => {
  const theme = useTheme();
  const buttonStyle = button(theme);

  if (typeLoading) {
    return (
      <LoadingButton {...buttonStyle} {...props}>
        {children}
      </LoadingButton>
    );
  } else {
    return (
      <Button {...buttonStyle} {...props}>
        {children}
      </Button>
    );
  }
};

export default ButtonS;
