import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

const color = '#00c621';

const Chart = ({
  coefficient,
  title,
  data,
  perDayTitle,
  range,
  toggleRange,
  visibleArrowRight,
  setVisibleArrowRight,
}) => {
  return (
    <Grid container sx={{ marginBottom: '20px' }}>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex' }}
      >
        <IconButton
          color="primary"
          sx={{ marginLeft: '30px', marginTop: '4px', marginBottom: '5px' }}
          onClick={async () => {
            const newCoefficient = coefficient + 1;
            if (newCoefficient > 1) setVisibleArrowRight(true);
            await toggleRange(range, newCoefficient);
          }}
        >
          <ArrowLeft />
        </IconButton>
        <Typography
          color="details"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        {visibleArrowRight && (
          <IconButton
            color="primary"
            sx={{ marginTop: '4px', marginBottom: '5px' }}
            onClick={async () => {
              const newCoefficient = coefficient - 1;
              await toggleRange(range, newCoefficient);
              if (newCoefficient === 1) setVisibleArrowRight(false);
            }}
          >
            <ArrowRight />
          </IconButton>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ display: 'flex ' }}
      >
        <LineChart
          width={370}
          height={200}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip labelStyle={{ color }} />
          <Legend />
          <Line
            type="monotone"
            dataKey={perDayTitle}
            stroke={color}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6} textAlign={'center'}>
        <FormControl>
          <RadioGroup
            aria-labelledby="theme-radio-buttons"
            value={range}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel
              value="1"
              control={<Radio onChange={() => toggleRange(1)} />}
              label={<FormattedMessage id="Week" />}
            />
            <FormControlLabel
              value="2"
              control={<Radio onChange={() => toggleRange(2)} />}
              label={<FormattedMessage id="Month" />}
            />
            <FormControlLabel
              value="3"
              control={<Radio onChange={() => toggleRange(3)} />}
              label={<FormattedMessage id="Year" />}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

export default Chart;
