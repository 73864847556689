import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { FormattedMessage } from 'react-intl';

const Loader = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={theme.palette.background.default}
      color={theme.palette.primary.main}
      style={{
        margin: 0,
        padding: 0,
        border: 0,
        outline: 0,
        verticalAlign: 'baseline',
      }}
    >
      <CircularProgress />
      <Box sx={{ marginTop: '26px' }}>
        <Typography variant="body1">
          <FormattedMessage id="Loading" />
        </Typography>
      </Box>
    </Box>
  );
};

export default Loader;
