import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Success = ({ text, color }) => {
  return (
    <Grid item xs={12} textAlign="center">
      <Typography color={color || 'naval'} fontWeight="bold">
        {text}
      </Typography>
    </Grid>
  );
};

export default Success;
