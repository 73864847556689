import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../components/ButtonS';
import Error from '../components/Error';
import FileUploader from '../components/FileUploader/FileUploader';
import Loader from '../components/Loader';
import Title from '../components/Title';

import errorMessageHandler from '../shared/utils/errorMessageHandler';

import { fetchApiMethods } from '../api/getMethods.js';
import Success from '../components/Success';

const Import = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const fileInputRef = React.createRef('');

  const handleUpload = async (event) => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const api = await fetchApiMethods();
      const file = event.target.files[0];
      fileInputRef.current = '';
      const result = await api.exchange.upload({ file });
      setLoading(false);
      setSuccess(result.message);
    } catch (error) {
      setLoading(false);
      setError(errorMessageHandler(error));
    }
  };

  if (loading) return <Loader />;

  return (
    <Grid container spacing={2}>
      <Title text={<FormattedMessage id="Import" />} />
      <FileUploader ref={fileInputRef} handleChange={handleUpload} />
      <Grid item xs={12} textAlign="center">
        <Button onClick={() => navigate('/menu')}>
          {<FormattedMessage id="Back" />}
        </Button>
      </Grid>
      {success && <Success text={success} />}
      {error && <Error text={error} />}
    </Grid>
  );
};

export default Import;
