import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';

import Button from '../ButtonS';

import css from './FileUploader.module.css';

const FileUploader = React.forwardRef(({ handleChange }, ref) => {
  return (
    <Grid item xs={12} textAlign="center">
      <div className={css.fileInput}>
        <Button>{<FormattedMessage id="ChooseFile" />}</Button>
        <input
          className={css.nativeInput}
          type="file"
          accept=".xlsx"
          ref={ref}
          onChange={handleChange}
        />
      </div>
    </Grid>
  );
});

FileUploader.displayName = 'FileUploader';

export default FileUploader;
