import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = ({ handleRemoveClick }) => {
  return (
    <IconButton
      sx={{ borderRadius: '50%', width: 48, height: 48 }}
      onClick={handleRemoveClick}
    >
      <DeleteIcon sx={{ color: 'red' }} />
    </IconButton>
  );
};

export default DeleteButton;
